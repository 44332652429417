<template>
  <div>
    <div class="title-display">
      <h1>What kind of taco do you want to send?</h1>
    </div>
    <form>
      <label>Please Enter a Taco:</label>
      <input v-model="taco" type="text" required />
      <button type="button" @click="updateTaco">Send Taco</button>
    </form>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "TacoInput",
  data() {
    return {
      taco: "",
    };
  },
  methods: {
    async updateTaco() {
      const token = await this.$auth.getTokenSilently();

      console.log("Update Taco");
      const request = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        url: "https://sandbox.api.playground.cardx.com",
        params: {
          taco: this.taco,
        },
      };
      console.log("Sending Request");
      const result = await axios(request);
      console.log("Received Response", result.data);
    },
  },
};
</script>

<style scoped>
form {
  max-width: 420px;
  margin: 30px auto;
  background: gray;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}
label {
  color: white;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.7em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
.title-display {
  margin: 40px 0 0;
  text-align: center;
}
a {
  color: #42b983;
}
</style>
