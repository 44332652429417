import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import TacoInput from "../components/TacoInput.vue";
import Profile from "../views/Profile.vue";
import { authGuard } from "../auth/authGuard.js";
import ExternalApiView from "../views/ExternalApi.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    beforeEnter: authGuard,
  },
  {
    path: "/send-tacos",
    name: "TacoInput",
    component: TacoInput,
    beforeEnter: authGuard,
  },
  {
    path: "/external-api",
    name: "external-api",
    component: ExternalApiView,
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
